

function GetCastOptions (data) {

    const options = new Array();

    data.map((obj,i)=>{
       const cast = obj.Cast;
       //console.log('cast', cast);
       const pattern = /[\p{L}\s-]+(?:\s+[\p{L}-]+)?/gu;
       const splitCast = cast.match(pattern);
       const actors = splitCast ? splitCast.map(actor => actor.trim()) : [];
       //const actors = splitCast.map((o)=>o.trim());
       //console.log('split cast', actors)
    //    if(actors.toLowerCase() !== 'director'){
    //      options.push(cast);
    //    }
    
        actors.map((o)=>{
            //console.log(o);
            if(o.toLowerCase() == 'unknown' || o.toLowerCase() == 'director' || o.toLowerCase() == 'screenplay'){return null}

            if(o.length >= 3){
                options.push(o);
            }
                
        })
    
       
    })


    // Do some preprocessing first...
    let counts = options.reduce((counts, num) => {
        counts[num] = (counts[num] || 0) + 1;
        return counts;
    }, {});
        
    options.sort(function(p0,p1){
        return counts[p1] - counts[p0];
    });

    // unique array 
    const uniqueArray = [...new Set(options)]

    //console.log(uniqueArray);

    return uniqueArray;

}

export default GetCastOptions;