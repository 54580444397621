import React from "react";
import './Filter.css'

function Filter({title, options, titleColour, setFilter, search, searchSelection, setSearchSelection}){

    const [filterSelected, setFilterSelected] = React.useState('');
    const [prevFilter, setPrevFilter] = React.useState('');

    const setFilterFn = (op) => {
        if(!op){return null};
        if(op !== prevFilter){
            console.log('this is the filter option', op);
            setFilter(op);
            setPrevFilter(op);
            setFilterSelected(op);
        }else{
            console.log('clearing filter');
            setFilter('');
            setPrevFilter('');
            setFilterSelected('false');
            setSearchSelection('');
        }
        
    }

    React.useEffect(()=>{
        console.log('this is the search selection', searchSelection);
        if(searchSelection !== ''){
            setFilterFn(searchSelection);
        }
        
    },[searchSelection])

    return(
        <div className="Filter">
            <h1 className="Title" style={{color: titleColour}}>{title} ({options.length})</h1>

            <div className="searchContainer" onClick={()=>search(true)}>
                <img className="searchIcon" src={require('../../resources/search-icon.png')} alt="" />
                <p className="searchTitle">Search {title}</p>
            </div>

            <ul>
                <li>
                    {
                    searchSelection !== options[0] ?
                    <a href='#' className={'OptionItem'} onClick={()=>{setFilterFn(options[0]);}}>
                        <h4 className={filterSelected == options[0] ? 'Active' : 'Option' }>{options[0]}</h4>
                        {
                            filterSelected == options[0] ? 
                            (
                                <img className="CrossIcon" src={require('../../resources/cross.png')} alt=""/>
                            ):null
                        }
                        
                    </a>
                    : null
                    }
                    
                </li>
                <li>
                    {
                       searchSelection !== options[1] ? 
                    <a href='#' className={'OptionItem'} onClick={()=>setFilterFn(options[1])}>
                        <h4 className={filterSelected == options[1] ? 'Active' : 'Option' }>{options[1]}</h4>
                        {
                            filterSelected == options[1] ? 
                            (
                                <img className="CrossIcon" src={require('../../resources/cross.png')} alt=""/>
                            ):null
                        }
                    </a>
                    :null
                    }
                </li>
                <li>
                    {
                      searchSelection !== options[2] ?  
                            <a href='#' className={'OptionItem'} onClick={()=> {searchSelection !== '' ? setFilterFn(searchSelection) : setFilterFn(options[2])}}>
                                <h4 className={filterSelected == options[2] || searchSelection ? 'Active' : 'Option' }>
                                    {searchSelection !== '' ? searchSelection : options[2]}
                                </h4>
                                {
                                    filterSelected == options[2] || searchSelection ? 
                                    (
                                        <img className="CrossIcon" src={require('../../resources/cross.png')} alt=""/>
                                    ):null
                                }
                            </a>
                        : null
                    }
                </li>
            </ul>
            
        </div>
    )
};

export default Filter;