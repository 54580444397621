import React from "react";
import * as d3 from "d3";
import './DotCanvas.css';
import dataset from '../../Data/wiki_movie.csv';
import DeckGL, { COORDINATE_SYSTEM, ScatterplotLayer, OrthographicView} from 'deck.gl';
import FilterUtility from "../../utility/filters/FilterUtility.js";
import Filter from "../filter/Filter.js";
import useWindowDimensions from "../../utility/dimensions/getDimensions.js";
import GetOriginOptions from "../../utility/filters/OriginOptions.js";
import GetGenreOptions from "../../utility/filters/GenreOptions.js"
import GetDirectorOptions from "../../utility/filters/DirectorOptions.js";
import GetCastOptions from "../../utility/filters/CastOptions.js";
import GetYearOptions from "../../utility/filters/YearOptions.js";
import DotModal from "../modal/DotModal.js";
import SearchModal from "../search/SearchModal.js";

function DotCanvas({setLoading}){

    //NEVER MUTATE THIS
    const [data, setData] = React.useState([]);
    const [maxDataLeng, setMaxDataLeng] = React.useState(30000);
    //MUTATE THIS
    const [filteredData, setFilteredData] = React.useState([]);
    const [pointSize, setPointSize] = React.useState(1);
    const [loaded, setLoaded] = React.useState(false);
    const [hoverInfo, setHoverInfo] = React.useState(null);

    //filters
    const [originFilter, setOriginFilter] = React.useState('');
    const [genreFilter, setGenreFilter] = React.useState('');
    const [directorFilter, setDirectorFilter] = React.useState('');
    const [castFilter, setCastFilter] = React.useState('');
    const [yearFilter, setYearFilter] = React.useState('');

    //used to animate points
    const [filterClicked, setFilterClicked] = React.useState(false);

    //options
    const [originOptions, SetOriginOptions] = React.useState();
    const [genreOptions, setGenreOptions] = React.useState();
    const [directorOptions, setDirectorOptions] = React.useState();
    const [castOptions, setCastOptions] = React.useState();
    const [yearOptions, setYearOptions] = React.useState();

    //Info Modal
    const [openModal, setOpenModal] = React.useState(false);
    const [modalItem, setModalItem] = React.useState(null);

    //Filter Search Modal
    const [openOriginModal, setOpenOriginModal] = React.useState(false);
    const [openGenreModal, setOpenGenreModal] = React.useState(false);
    const [openDirectorModal, setOpenDirectorModal] = React.useState(false);
    const [openCastModal, setOpenCastModal] = React.useState(false);
    const [openYearModal, setOpenYearModal] = React.useState(false);

    //Selected Search
    const [originSearchSelection, setOriginSearchSelection] = React.useState('');
    const [genreSearchSelection, setGenreSearchSelection] = React.useState('');
    const [directorSearchSelection, setDirectorSearchSelection] = React.useState('');
    const [castSearchSelection, setCastSearchSelection] = React.useState('');
    const [yearSearchSelection, setYearSearchSelection] = React.useState('');

    const WindowDimensions = useWindowDimensions();
    const [windowWidth, setWindowWidth] = React.useState(WindowDimensions.width)
    const {width, height} = useWindowDimensions();


    const getRandomCoordinateInCircle = radius => {
        const a = Math.random() * Math.PI * 2;
        const r = radius * Math.sqrt(Math.random() + (Math.random()-Math.random()));

        const x = r * Math.cos(a);
        const y = r * Math.sin(a);

        return { x, y };
    };

    React.useEffect(()=>{
        if(loaded === false){

            d3.csv(dataset).then(function(res) {
                console.log('data loading')
                
                const arr = []
                res.map((obj,i) => {
                    const {x, y} = getRandomCoordinateInCircle((width*0.5)/3.5 );
                    if(isNaN(x) && isNaN(y)){return null}
                    obj.pos = [x,y];
                    arr.push(obj);
                });

                setFilteredData(arr);
                setData(arr);
                setMaxDataLeng(arr.length);
                setLoaded(true);
                setLoading(true);
                //console.log(arr.length);

                //get options
                SetOriginOptions(GetOriginOptions(arr));
                setGenreOptions(GetGenreOptions(arr));
                setDirectorOptions(GetDirectorOptions(arr));
                setCastOptions(GetCastOptions(arr));
                setYearOptions(GetYearOptions(arr));
                
            }).catch(function(err) {
                throw err;
            })

        }
    },[]);

   React.useEffect(()=>{
    if(loaded){
        const filteredData = FilterUtility(data, originFilter, genreFilter, yearFilter, directorFilter, castFilter);
        setFilteredData(filteredData);
        SetOriginOptions(GetOriginOptions(filteredData));
        setGenreOptions(GetGenreOptions(filteredData));
        setDirectorOptions(GetDirectorOptions(filteredData));
        setCastOptions(GetCastOptions(filteredData));
        setYearOptions(GetYearOptions(filteredData));
        setFilterClicked(true);
        
        mapRange(filteredData);
    }
   },[loaded, pointSize, originFilter, genreFilter, yearFilter, directorFilter, castFilter]);


    const mapRange = (data) => {
        const s = d3.scalePow([1, maxDataLeng], [4, 1]).exponent(0.2);
        setPointSize(s(data.length));
    }

    //filteredData.length >= data.length ? [132, 112, 255] : [255, 18, 203]

    const Layer = [
        new ScatterplotLayer({
        id: 'Dokita-Dots',
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        data: filteredData,
        pickable: true,
        opacity: 0.8,
        filled: true,
        radiusScale: 1,
        getPosition: d => filterClicked ? d.pos : 0,
        getRadius: d => pointSize,
        getFillColor: d => filteredData.length >= data.length ? [132, 112, 255] : [255, 18, 203],
        onHover: info => setHoverInfo(info),
        onClick: info => {setModalItem(info);setOpenModal(true)},
        transitions: {
            // transition with a duration of 3000ms
            getFillColor: 1000,
            getRadius: {
                duration: 500,
                easing: d3.easeBackInOut,
            },
            getPosition: {
                duration: 1000,
                easing: d3.easeBackInOut,
            }
        },
    })
    ]

    if(loaded === false){return null}
    return(
        <div className="dotCanvas">

            <DeckGL
                id={'DeckGlCanvas'}
                views={new OrthographicView()}
                width={windowWidth*0.9}
                height={height*0.75}
                style={{left: 0, position: 'relative', borderRadius: 1000}}
                layers={Layer}
                pickingRadius={5}
            >

                <div class="main">
                    <div class="circle">
                        <Filter title={'Origin'} options={originOptions} titleColour={'#BE8BFF'} setFilter={setOriginFilter} search={setOpenOriginModal} searchSelection={originSearchSelection} setSearchSelection={setOriginSearchSelection} />
                    </div>
                    <div class="circle">
                        <Filter title={'Genre'} options={genreOptions} titleColour={'#FF9494'} setFilter={setGenreFilter} search={setOpenGenreModal} searchSelection={genreSearchSelection} setSearchSelection={setGenreSearchSelection} />
                    </div>
                    <div class="circle">
                        <Filter title={'Director'} options={directorOptions} titleColour={'#94FFC5'} setFilter={setDirectorFilter} search={setOpenDirectorModal} searchSelection={directorSearchSelection} setSearchSelection={setDirectorSearchSelection}/>
                    </div>
                    <div class="circle">
                        <Filter title={'Cast'} options={castOptions} titleColour={'#FFD494'} setFilter={setCastFilter} search={setOpenCastModal} searchSelection={castSearchSelection} setSearchSelection={setCastSearchSelection} />
                    </div>
                    <div class="circle">
                        <Filter title={'Year'} options={yearOptions} titleColour={'#8BB3FF'} setFilter={setYearFilter} search={setOpenYearModal} searchSelection={yearSearchSelection} setSearchSelection={setYearSearchSelection}/> 
                    </div>
                 
                </div>

                {hoverInfo != null && hoverInfo.object ? (
                    <div style={{
                                position: 'absolute', 
                                zIndex: 1, 
                                pointerEvents: 'none', 
                                left: hoverInfo.x, 
                                top: hoverInfo.y,
                                backgroundColor: '#1B1739',
                                opacity: 0.9,
                                color: '#fff',
                                padding: 12,
                                borderRadius: 10
                            }}>
                        {hoverInfo.object['Title']}
                    </div>
                ):null}


                {
                    filteredData.length !== data.length ?
                        <div className="pointsShowingContainer">
                            <div className="pointsShowingBox"></div>
                            <p className="pointsShowingTitle">showing {filteredData.length} data points</p>
                        </div>
                    : null
                }
                
            </DeckGL>

            <DotModal openModal={openModal} modalItem={modalItem} setOpenModal={setOpenModal} />

            <SearchModal openModal={openOriginModal} setOpenModal={setOpenOriginModal} data={originOptions} title={'Origin'} setSelection={setOriginSearchSelection} />
            <SearchModal openModal={openGenreModal} setOpenModal={setOpenGenreModal} data={genreOptions} title={'Genre'} setSelection={setGenreSearchSelection} />
            <SearchModal openModal={openDirectorModal} setOpenModal={setOpenDirectorModal} data={directorOptions} title={'Director'} setSelection={setDirectorSearchSelection} />
            <SearchModal openModal={openCastModal} setOpenModal={setOpenCastModal} data={castOptions} title={'Cast'} setSelection={setCastSearchSelection} />
            <SearchModal openModal={openYearModal} setOpenModal={setOpenYearModal} data={yearOptions} title={'Year'} setSelection={setYearSearchSelection} />
        </div>
                
    );
            
  
}

export default DotCanvas;