import React from "react";
import Modal from 'react-modal';
import SearchBar from './SearchBar';
import './SearchModal.css'

const styles = {
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)'
        },
        content: {
          position: 'absolute',
          top: '120px',
          left: '25%',
          right: '25%',
          bottom: '120px',
          background: '#1A1638',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '10px',
          border: 'none',
          padding: '40px',  
          
        }
    }

function SearchModal({openModal, setOpenModal, data, title, setSelection}){

    Modal.setAppElement('.App')
    // console.log(data)

    const setSearchSelectionFn = (selection) => {
        setSelection(selection);
    }
    
    return(
        <Modal
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            contentLabel="Search Modal"
            style={styles}
        >
            <div className='searchHeader'>
                    <h1>Search to find a filter</h1>
                    <a href='#' onClick={()=> setOpenModal(false)}>
                        <img className='cross' src={require('../../resources/cross.png')} alt=""/>
                    </a>
            </div>
             <SearchBar data={{data}} title={title} setSelection={setSearchSelectionFn} setOpenModal={setOpenModal}/>
        </Modal>
    )
}

export default SearchModal;