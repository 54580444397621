import React from "react";
import ReactSearchBox from "react-search-box";

function SearchBar({data, title, setSelection, setOpenModal}) {

    const [loaded, setLoaded] = React.useState();
    const [searchData, setSearchData] = React.useState();

    React.useEffect(()=>{
        //console.log(data.data)
        const output = data.data.map((o,i) => ({key: i, value: o}));
        setSearchData(output);
        setLoaded(true);
    },[])

    const setSearchSelection = (item)=>{
        setOpenModal(false);
        setSelection(item)
    }

    if(!loaded){return null;}
    return(
        <ReactSearchBox
            placeholder={`Search ${title}`}
            value="Doe"
            data={searchData}
            onSelect={(s)=>{setSearchSelection(s.item.value)}}
            leftIcon={<>🔍</>}
            iconBoxSize="48px"
        />
    )
}

export default SearchBar;