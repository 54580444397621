import React from "react";
import './LoadScreen.css';

function LoadScreen(){

    return(
        <div className="LoadScreenContainer">

            <h1 className="DokitaLoadingTitle">Dokita</h1>
            <h4 className="Tagline">For visual big data exploration</h4>
            <div>
                <a href='https://www.axamattic.com' target='_blank' rel="noreferrer">
                    <img className='axamatticBadge' src={require('../../resources/axamatticBadge.png')} alt=""/>
                </a>
            </div>

            <div class="pl">
            <svg class="pl__rings" viewBox="0 0 128 128" width="128px" height="128px">
                <g fill="none" stroke-linecap="round" stroke-width="4">
                    <g class="pl__ring" transform="rotate(0)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.3)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(0)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(0)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(0)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(180)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.3)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(180)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(0)">
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                    </g>
                    <g class="pl__ring" transform="rotate(180)">
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />	
                    </g>
                </g>
                </svg>
                <div class="pl__nucleus">
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                    <div class="pl__nucleus-particle"></div>
                </div>
            </div>

        
        </div>
    )
}

export default LoadScreen;