

function GetDirectorOptions (data) {

    const options = new Array();

    data.map((obj,i)=>{
       const director = obj.Director;
       if(director.toLowerCase() !== 'unknown'){
        options.push(director);
       }
       
    })

    // Do some preprocessing first...
    let counts = options.reduce((counts, num) => {
        counts[num] = (counts[num] || 0) + 1;
        return counts;
    }, {});
        
    options.sort(function(p0,p1){
        return counts[p1] - counts[p0];
    });

    // unique array 
    const uniqueArray = [...new Set(options)]

    //console.log(uniqueArray);

    return uniqueArray;

}

export default GetDirectorOptions;