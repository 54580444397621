import React from "react";
import './App.css';
import DotCanvas from './components/dot/DotCanvas';
import About from './components/about/About';
import LoadScreen from "./components/loadScreen/LoadScreen";

function App() {

    //About 
    const [isAboutOpen, setIsAboutOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

  return (
    <div className="App">
     
     {
      loading !== true ?
        <LoadScreen/>
      :
        null    
      }

      <About isOpen={isAboutOpen} setIsOpen={setIsAboutOpen}/>

      <div className='sidePanel'>
        <h1 className='DokitaTitle'>Dokita</h1>
        <div className='AboutButton' onClick={()=>setIsAboutOpen(!isAboutOpen)}>
          <p className='AboutTitle'>About</p>
        </div>
          <a href='https://www.axamattic.com' target='_blank' rel="noreferrer">
            <img className='axamatticBadge' src={require('./resources/axamatticBadge.png')} alt=""/>
          </a>
      </div>
      <div className='dokitaCanvas'>
            <DotCanvas setLoading={setLoading}/> 
      </div>
    </div>
  );
}

export default App;
