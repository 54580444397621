

function FilterUtility (data, originFilter, genreFilter, yearFilter, directorFilter, castFilter) {

    const originQuery = originFilter !== '' ?  o => o['Origin/Ethnicity'].toLowerCase() === originFilter.toLowerCase() : o => o
    const genreQuery = genreFilter !== '' ?  o => o.Genre.toLowerCase() === genreFilter.toLowerCase() : o => o
    const yearQuery = yearFilter !== '' ?  o => o['Release Year'].toLowerCase() === yearFilter.toLowerCase() : o => o
    const directorQuery = directorFilter !== '' ?  o => o.Director.toLowerCase() === directorFilter.toLowerCase() : o => o
    const castQuery = castFilter !== '' ?  o => o.Cast.toLowerCase().includes(castFilter.toLowerCase()) : o => o

    const filteredData = data.filter(originQuery)
                            .filter(genreQuery)
                            .filter(yearQuery)
                            .filter(directorQuery)
                            .filter(castQuery);
    
    //console.log(filteredData);

    return filteredData;
}

export default FilterUtility;