import Modal from 'react-modal';
import './DotModal.css';
import ReadMoreReact from 'read-more-react';

const styles = {
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)'
        },
        content: {
          position: 'absolute',
          top: '120px',
          left: '25%',
          right: '25%',
          bottom: '120px',
          background: '#1A1638',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '10px',
          border: 'none',
          padding: '40px',  
          
        }
    }

function DotModal({openModal, modalItem, setOpenModal}){

    //console.log(modalItem)
    Modal.setAppElement('.App')
    

    if(!modalItem){return null}
    return(
        <Modal
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            contentLabel="Dot Modal"
            style={styles}
        >
            <div className='header'>
                <h1 className='title'>{modalItem.object.Title}</h1>
                <a href='#' onClick={()=>setOpenModal(false)}>
                    <img className='cross' src={require('../../resources/cross.png')} alt=""/>
                </a>
            </div>

            <div className='plotContainer'>
                {/* <p className='plot'>{modalItem.object.Plot}</p> */}
                <ReadMoreReact 
                    text={modalItem.object.Plot}
                    min={200}
                    ideal={250}
                    max={300}
                    readMoreText="Read more"
                />
            </div>

            <div className='infoBox'>
                <h3>Release Year</h3>
                <p>{modalItem.object['Release Year']}</p>
            </div>

            <div className='infoBox'>
                <h3>Director</h3>
                <p>{modalItem.object.Director}</p>
            </div>

            <div className='infoBox'>
                <h3>Genre</h3>
                <p>{modalItem.object.Genre}</p>
            </div>

            <div className='infoBox'>
                <h3>Cast</h3>
                <p>{modalItem.object.Cast}</p>
            </div>

            <div className='infoBox'>
                <h3>Origin/Ethnicity</h3>
                <p>{modalItem.object['Origin/Ethnicity']}</p>
            </div>

            <div className='infoBox'>
                <h3>Wiki Page</h3>
                <a href={modalItem.object['Wiki Page']} target='_blank' rel="noreferrer">
                    <p>{modalItem.object['Wiki Page']}</p>
                </a>
                
            </div>
            
        </Modal>
    )
}

export default DotModal;