import React from 'react'
import Modal from 'react-modal';
import './About.css'

const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    content: {
      position: 'absolute',
      top: '120px',
      left: '25%',
      right: '25%',
      bottom: '120px',
      background: '#1A1638',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '10px',
      border: 'none',
      padding: '70px',      
    }
}

function About({isOpen, setIsOpen}){


    const closeDrawer = () => {
        setIsOpen(false);
    }

    return(
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => closeDrawer()}
                contentLabel="About Modal"
                style={styles}
            >

                <div className='header'>
                    <h1 className='aboutTitle'>About Dokita</h1>
                    <a href='#' nClick={()=> closeDrawer()}>
                        <img className='cross' src={require('../../resources/cross.png')} alt=""/>
                    </a>
                </div>

                <p className='bodyCopy'>
                Organisations of all sizes and shapes are drowning in an ever-growing mass of data and there is a lack of tools that make it easy to inspect and explore large datasets that may contain thousands or even millions of items.
                </p>
                <p className='bodyCopy'>
                    Dokita is a visual data tool that maps out the entire landscape of a dataset out allowing organisations to explore large datasets without using complicated databases or overwhelming dashboards.
                </p>
                <p className='bodyCopy'>
                    We’re happy to have a conversation around custom functionality so get in touch to see how we could help you with Dokita.
                </p>

                <h4 className='axaAboutBadge'>Axamattic Information Design</h4>
                <a className='axaAboutLink' href='https://www.axamattic.com' target='_blank' rel="noreferrer">axamattic.com</a>

             </Modal>
        </>
    );
}

export default About;